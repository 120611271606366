import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { UpdateOnboardingInfoRequest } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatAsISODate } from "@libs/utils/date";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { Button } from "@libs/components/UI/Button";
import { RadioList } from "@libs/components/UI/RadioList";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { getPatientInsurancesQuery } from "api/patientInsurance/queries";
import { InsurancesList } from "components/PatientInsurances/InsurancesList";

import { RegistrationSubmissionFooter } from "components/Onboarding/RegistrationSubmissionFooter";
import { AddPatientInsuranceForm } from "components/PatientInsurances/AddPatientInsuranceForm";
import { useInsuranceDraft } from "components/PatientInsurances/hooks/useInsuranceDraft";
import {
  getAddInsuranceFormFields,
  useSubmitInsurance,
} from "components/PatientInsurances/hooks/useSubmitInsurance";
import { semibold16 } from "assets/styles/textSize";
import { AddButton } from "components/UI/AddButton";
import { useSignature } from "components/Onboarding/hooks/useSignature";
import { SignedRelease } from "components/PatientForms/SignedRelease";
import { useHandleError } from "api/handleErrorResponse";
import { updateOnboardingInfo } from "api/user/mutations";
import { defaultSnackbarOptions } from "utils/snackbar";
import { OnboardingStep } from "components/Onboarding/hooks/useOnboardingOrder";

type Props = {
  onPrevious?: Func;
  onSuccess: Func;
};

// eslint-disable-next-line complexity
export const OnboardingInsurance: React.FC<Props> = ({ onPrevious, onSuccess }) => {
  const { id: patientId, practiceId } = useAccount();

  const practice = useCurrentPractice();

  const { t } = useTranslation();
  const isAddingPrimaryInsurance = useBoolean(false);
  const [insurancesQuery] = useApiQueries([getPatientInsurancesQuery({ args: { patientId, practiceId } })]);
  const { data: insurances, isLoading } = insurancesQuery;
  const isAddingSecondaryInsurance = useBoolean(false);

  const insuranceCount = insurances?.length ?? 0;
  const hasInsurance = insuranceCount > 0;
  const canAddInsurance =
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    insuranceCount < 2 && isAddingSecondaryInsurance.isOff && (hasInsurance || isAddingPrimaryInsurance.isOn);
  const didAddInsurance = hasInsurance || isAddingPrimaryInsurance.isOn;

  const { validation: signValidation, signature, updateSignature } = useSignature();
  const primaryInsurance = useInsuranceDraft();
  const primaryInsuranceSubmission = useSubmitInsurance({
    draftInsurance: primaryInsurance.draftInsurance,
  });

  const secondaryInsurance = useInsuranceDraft();
  const secondaryInsuranceSubmission = useSubmitInsurance({
    draftInsurance: secondaryInsurance.draftInsurance,
  });

  const { enqueueSnackbar } = useSnackbar();
  const [{ mutateAsync: mutateOnboardingAsync, isLoading: isSubmitting }] = useApiMutations([
    updateOnboardingInfo,
  ]);
  const handleError = useHandleError();
  const handleUpdateOnboardingState = React.useCallback(
    async (params: { onboardingState: OnboardingStep; data: UpdateOnboardingInfoRequest }) => {
      if (!practiceId || !patientId) {
        // To investigate https://sentry.io/organizations/grindfoundry/issues/3423956788/?project=6000839&referrer=slack
        handleError(
          new Error(`assertion failed, ids undefined patientId:${patientId}, practiceId:${practiceId}`)
        );
      }

      try {
        await mutateOnboardingAsync({
          practiceId,
          patientId,
          ...params,
        });
        onSuccess();
      } catch (e) {
        if (isHttpResponseError(e)) {
          const [error] = e.error.errors ?? [];

          if (error.field && error.field.includes("generalInfo")) {
            enqueueSnackbar(error.message, { ...defaultSnackbarOptions, variant: "error" });
          } else {
            handleError(e);
          }
        }
      }
    },
    [enqueueSnackbar, handleError, mutateOnboardingAsync, onSuccess, patientId, practiceId]
  );

  const handleNextClicked = React.useCallback(() => {
    let insuranceOnboardData: UpdateOnboardingInfoRequest = {};

    if (!didAddInsurance) {
      insuranceOnboardData = {
        insuranceInfo: {
          primaryInsurance: { type: "NONE" },
        },
      };
    }

    const primaryValidation = isAddingPrimaryInsurance.isOn
      ? primaryInsuranceSubmission.validate().$isValid
      : true;
    const secondaryValidation = isAddingSecondaryInsurance.isOn
      ? secondaryInsuranceSubmission.validate().$isValid
      : true;
    const isSigned = signValidation.validate().$isValid;

    if (primaryValidation && secondaryValidation && isSigned) {
      insuranceOnboardData = {
        insuranceInfo: {
          primaryInsurance: isAddingPrimaryInsurance.isOn
            ? getAddInsuranceFormFields(primaryInsurance.draftInsurance)
            : { type: "NONE" },
          secondaryInsurance: isAddingSecondaryInsurance.isOn
            ? getAddInsuranceFormFields(secondaryInsurance.draftInsurance)
            : undefined,
          signature: {
            text: signature.signatureText,
            date: formatAsISODate(new Date()),
            type: "INSURANCE_INFO_AUTHORIZED",
          },
        },
      };
    }

    handleUpdateOnboardingState({ data: insuranceOnboardData, onboardingState: "INSURANCE_INFO" });
  }, [
    didAddInsurance,
    isAddingPrimaryInsurance.isOn,
    primaryInsuranceSubmission,
    isAddingSecondaryInsurance.isOn,
    secondaryInsuranceSubmission,
    signValidation,
    handleUpdateOnboardingState,
    primaryInsurance.draftInsurance,
    secondaryInsurance.draftInsurance,
    signature.signatureText,
  ]);

  return (
    <>
      <div className="px-4 space-y-4">
        {!hasInsurance && !isLoading && (
          <RadioList
            className="mt-5"
            label={t("Do you have dental insurance?")}
            required
            selectedValue={isAddingPrimaryInsurance.isOn ? "1" : "0"}
            options={[
              { value: "1", label: t("Yes") },
              { value: "0", label: t("No") },
            ]}
            onChange={(e) => {
              isAddingPrimaryInsurance.set(e.target.value === "1");
              isAddingSecondaryInsurance.off();
            }}
          />
        )}
        <div>
          <InsurancesList query={insurancesQuery} />
        </div>
        {isAddingPrimaryInsurance.isOn && (
          <div className="space-y-2">
            <div className={cx("text-secondaryTheme", semibold16)}>{t("Primary Insurance")}</div>
            <AddPatientInsuranceForm
              id="registration-add-primary-insurance"
              mutableInsurance={primaryInsurance}
              insuranceSubmission={primaryInsuranceSubmission}
            />
          </div>
        )}
        {canAddInsurance && (
          <AddButton onClick={isAddingSecondaryInsurance.on}>{t("Add Insurance")}</AddButton>
        )}
        {isAddingSecondaryInsurance.isOn && (
          <div className="space-y-2">
            <div className="flex justify-between">
              <div className={cx("text-secondaryTheme", semibold16)}>{t("Secondary Insurance")}</div>
              <Button
                theme="link"
                onClick={isAddingSecondaryInsurance.off}
                className="flex gap-1 items-center"
              >
                <DeleteIcon className="w-4 h-4" />
                Delete
              </Button>
            </div>
            <AddPatientInsuranceForm
              id="registration-add-secondary-insurance"
              mutableInsurance={secondaryInsurance}
              insuranceSubmission={secondaryInsuranceSubmission}
            />
          </div>
        )}
      </div>
      <div className="px-4 my-4">
        <div className="h-[1px] bg-greyLighter w-full" />
      </div>

      {didAddInsurance && (
        <SignedRelease
          className="px-4"
          id="patient-insurance-signature"
          title={t("Release of patient information")}
          checkboxText={
            <Trans
              i18nKey="app.registration.insurance.consent"
              values={{ practiceName: practice.name }}
              tOptions={{ interpolation: { escapeValue: true } }}
              shouldUnescape
            />
          }
          validation={signValidation}
          onUpdateSignature={updateSignature}
          signature={signature}
        />
      )}
      <RegistrationSubmissionFooter
        isSubmitting={isSubmitting}
        onClickPrevious={onPrevious}
        onClickNext={handleNextClicked}
      />
    </>
  );
};
